import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';

import Sections from '@components/Sections';
import Button from '@core/Button';
import { scrollTo } from '@utils';
import ContactSection from '@components/ContactSection';
import { hover } from '@mixins';

const MeetingroomsPage = ({
  data: {
    contentfulWorkshopsPage: {
      title,
      description,
      callToActionText,
      sections,
      contactName,
      contactTitle,
      contactInfo,
      contactImage,
    },
  },
}) => {
  const scrollToForm = useCallback(() => {
    scrollTo('#workshops', true);
  }, []);

  return (
    <>
      <Seo
        pageTitle={title}
        pageDescription="Booking your next team workshop, meeting or seminar in Berlin?"
      />
      <Main>
        <TopSection>
          <Title>{title}</Title>
          <Description text={description} />
          {callToActionText && (
            <CallToAction large onClick={scrollToForm}>
              {callToActionText}
            </CallToAction>
          )}
        </TopSection>
        <Sections sections={sections} />
        <ContactSection
          contactName={contactName}
          contactTitle={contactTitle}
          contactInfo={contactInfo}
          contactImage={contactImage}
        />
      </Main>
    </>
  );
};

const defaultTextColumns = css`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const Main = styled.main`
  [class*='LinkSection__Container'] {
    a {
      --content-color: var(--color-secondary);
      --background-color: var(--color-text);
    }
  }
`;

const TopSection = styled.section`
  padding-bottom: 0;
  margin-bottom: var(--space-s);

  ${mq.small} {
    margin-bottom: var(--space-l);
  }
`;

const Title = styled.h1`
  ${defaultTextColumns};
  margin-bottom: var(--space-s);

  ${mq.small} {
    margin-bottom: var(--space-m);
  }
`;

// TODO
// since it's repeating (like the main title), perhaps move out
const Description = styled(RichText)`
  ${defaultTextColumns};
  text-align: center;

  p a {
    display: inline;
  }
`;

const CallToAction = styled(Button)`
  grid-column: 1 / -1;
  justify-self: center;

  margin-top: var(--space-s);

  ${mq.small} {
    margin-top: var(--space-m);
  }
`;

export const workshopsPageQuery = graphql`
  query WorkshopsPageQuery($locale: String) {
    contentfulWorkshopsPage(node_locale: { eq: $locale }) {
      node_locale
      title
      description {
        raw
      }
      callToActionText
      contactName
      contactTitle
      contactInfo {
        raw
      }
      contactImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(MeetingroomsPage);
